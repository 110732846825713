<!--
 * @Author: Eric hongyong.yang@nttdata.com
 * @Date: 2023-03-14 21:59:50
 * @LastEditors: Eric hongyong.yang@nttdata.com
 * @LastEditTime: 2023-03-27 16:49:46
 * @FilePath: \edp-web\src\views\noAccess.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="edp-home">
    <edp-header class="header-bg"></edp-header>
    <div class="no-wrap tac">
      <img src="@/src/assets/home/404.png" alt="" class="no-logo" />
      <P>{{ $t("member.noAccess") }}</P>
      <img src="@/src/assets/home/no-bg.png" alt="" class="no-bg" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
.edp-home {
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    166.25deg,
    #ffffff 1.65%,
    rgba(250, 250, 250, 0) 91.83%
  );
  .header-bg {
    background: #fff;
  }
  .no-wrap {
    .no-logo {
      width: toPad(164);
      height: toPad(142);
      margin-top: 20vh;
    }
    .no-bg {
      width: toPad(1294);
      height: toPad(414);
      margin-top: 8vh;
    }
  }
}
</style>
